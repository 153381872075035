<template>
  <div class="result">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>兑换结果</div>
    </div>
    <div class="result_img">
      <img :src="success?successImg:failImg" alt />
    </div>
    <div class="result_text">{{success?"兑换成功":"兑换失败"}}</div>
    <div class="btns">
      <div class="left_btn" @click="handleIndex">返回首页</div>
      <div class="right_btn btn" @click="handleClick">{{success?"查看订单":"重新兑换"}}</div>
    </div>
  </div>
</template>
<script> 
export default {
  data(){
    return{
      success:true,
      successImg:require("../../assets/image/success.svg"),
      failImg:require("../../assets/image/fail.svg"),
    }
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    handleIndex(){
        this.$router.push("/index")

    },
    handleClick(){
      if(this.success == true){
        this.$router.push("/order/index")
      }else{
        this.$router.push("/index")
      }
    }
  },
  mounted(){
    this.success = this.$route.query.success;
  }
};
</script>
<style lang="scss" scoped>
.result {
  width: 375px;
  padding-top: 40px;
  box-sizing: border-box;
  .result_img {
    width: 145px;
    height: 133px;
    margin: 0 auto;
    margin-top: 70px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .result_text {
    font-size: 18px;
    color: rgba(51, 51, 51, 1);
    line-height: 18px;
    margin-top: 30px;
    text-align: center;
  }
  .btns {
    margin-top: 87px;
    display: flex;
    justify-content: space-between;
    padding: 0 37px;
    box-sizing: border-box;
    align-items: center;
    .left_btn {
      width: 140px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #00b100;
      border-radius: 23px;
      font-size: 16px;
      color: rgba(0, 177, 0, 1);
    }
    .right_btn{
      width:140px;
      margin:0;
    }
  }
}
</style>